/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "44bits 팟캐스트 118번째 로그에서는 당근마켓 신규 오피스 이사, tailscale VPN에 대해서 이야기를 나누었습니다."), "\n", React.createElement(_components.h3, null, "당근마켓 신규오피스 이사"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.kyobotower.co.kr/tower/indexTower.html;krwebTower=DFE3EA1178296285955A258EF21129A6"
  }, "강남교보타워")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hermanmiller.com/en_apc/products/seating/office-chairs/aeron-chairs/"
  }, "허먼밀러 에어론 의자")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://envoy.com/"
  }, "Envoy Visitors, Protect, Desks, Rooms and Deliveries")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://zoom.us/zoomrooms"
  }, "zoom rooms")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.logitech.com/ko-kr/products/video-conferencing/conference-cameras/rally-ultra-hd-ptz-camera.960-001226.html"
  }, "로지텍 RALLY 카메라")), "\n"), "\n", React.createElement(_components.h3, null, "tailscale"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://tailscale.com/"
  }, "Tailscale: Best VPN Service for Secure Networks")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
